<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo bilge-logo" :to="{ path: '/' }">
      <b-img :src="brandLogo" alt="Logo" />
      <h2
        class="brand-text ml-1 mb-0 d-none d-md-block"
        :class="{
          'text-white': skin === 'dark',
          'text-primary': skin !== 'dark'
        }"
      >
        {{ brandTitle }}
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <div class="d-flex justify-content-center align-items-center flex-column mb-1">
          <feather-icon size="120" icon="CheckIcon" class="text-success"></feather-icon>
          <h2>
            {{ $t('order.order_thanks.title') }}
          </h2>
        </div>
        <h4 class="mb-1">
          {{ $t('order.order_thanks.order_no', { order_no: $route.params.id }) }}
        </h4>
        <div class="mb-3">
          <span> {{ $t('order.order_thanks.message_1') }} </span>
          <b-link :to="{ name: '/Shop/OrderDetail', params: { id: $route.params.id } }"> {{ $t('order.order_thanks.click_me_1') }}</b-link>
          <span> {{ $t('order.order_thanks.message_2') }} </span>
          <b-link :to="{ name: '/Shop/Orders' }"> {{ $t('order.order_thanks.click_me_2') }} </b-link>
          <span> {{ $t('order.order_thanks.message_3') }} </span>
        </div>

        <b-button variant="primary" class="btn-md" @click="backHome()">
          {{ $t('BackToHome') }}
        </b-button>

        <b-img fluid src="@/assets/images/pages/order_complete.svg" alt="Order complete page" />
      </div>
    </div>
  </div>
  <!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import store from '@/store/index'
import { mapGetters } from 'vuex'

export default {
  components: {
    BLink,
    BButton,
    BImg
  },
  computed: {
    ...mapGetters(['brandLogo', 'brandTitle']),
    skin() {
      return store.state.appConfig.layout.skin
    }
  },
  created() {
    this.$i18n.locale = localStorage.bilgeCodeLocale
    document.title = `${this.brandTitle} - ${this.$t(this.$route.meta.title)}`
  },
  methods: {
    backHome() {
      this.$router.push({
        name: 'home'
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
.bilge-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 200px;
  }
  h2 {
    font-weight: 600;
  }
}
</style>
